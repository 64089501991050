import { defineStore } from 'pinia'
import { store } from '..'
import { globalAPI } from '@/api/global'
import Taro from '@tarojs/taro'
import { toRaw } from 'vue'
import { localReference } from '@/types/local-reference'


/* 全局商店状态 */
interface GlobalState {
  reference: Nullable<
    Partial<{ [key in Reference.ReferenceKey]: Reference.ReferenceItem[] }>
  >;
  systemInfo: Nullable<{
    windowWidth: number;
    windowHeight: number;
    screenWidth: number;
    screenHeight: number;
  }>;
  miniProgramInfo: Nullable<Taro.getAccountInfoSync.MiniProgram>;
  isShowShareGuide: boolean;
  browserDownloadShow: boolean;
  orderCenterSearchType: number | undefined;
}


export const useGlobalStore = defineStore('Global', {
  state: (): GlobalState => ({
    reference: null, // 枚举列表
    systemInfo: null, // 系统信息
    miniProgramInfo: null,
    isShowShareGuide: false,
    browserDownloadShow: false,
    orderCenterSearchType: undefined, // 订单中心订单的默认类型
  }),
  actions: {

    /* 获取枚举值列表 */
    getReferenceItems(referenceKey: Reference.ReferenceKey): Reference.ReferenceItem[] {
      return this.reference?.[referenceKey] || []
    },

    /* 获取枚举值的值映射列表 */
    getReferenceItemsValueMap(referenceKey: Reference.ReferenceKey): Record<number, Reference.ReferenceItem> {
      let valueMap: Record<number, Reference.ReferenceItem> = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        valueMap[item.value] = toRaw(item)
      })
      return valueMap
    },

    /* 获取枚举值的值映射列表 */
    getReferenceItemsLabelMap(referenceKey: Reference.ReferenceKey): Record<string, Reference.ReferenceItem> {
      let labelMap: Record<string, Reference.ReferenceItem> = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        labelMap[item.label] = toRaw(item)
      })
      return labelMap
    },

    /* 获取枚举值常量 map */
    getReferenceConstantMap<T extends Reference.ReferenceKey>(referenceKey: T): Reference.ConstantKeyMap[T] {
      let constantMap = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        constantMap[item.key!] = toRaw(item)
      })
      return constantMap as Reference.ConstantKeyMap[T]
    },

    /* 设置枚举列表 */
    async setReference() {

      /* 类型注释 */
      interface ColorOption {
        referenceKey: Reference.ReferenceKey;
        constantKey: string;
        colorValue: string;
      }

      /* 数据 */
      const colorOptions: ColorOption[] = [

        /* verifyStatus */
        // {
        //   referenceKey: 'verifyStatus',
        //   constantKey: '-1',
        //   colorValue: commonColorEnum.red,
        // },
      ]

      /* 方法 */
      const setColor = <T extends Reference.ReferenceKey>(referenceKey: T, constantKey, colorValue, showText?: string) => {
        const constantMap = this.getReferenceConstantMap(referenceKey)
        constantMap[constantKey].color = colorValue
        if (showText) {
          constantMap[constantKey].showText = showText
        }
      }

      this.reference = await globalAPI.getReference()
      this.reference = Object.assign(localReference, this.reference)

      /** 设置枚举颜色值 */
      const auditStatusEnum = this.getReferenceConstantMap('auditStatusEnum')
      auditStatusEnum.WAIT.color = 'rgba(217, 217, 217, 1)'
      auditStatusEnum.PASS.color = 'rgba(68, 142, 246, 1)'
      auditStatusEnum.REJECT.color = 'rgba(250, 50, 25, 1)'
      auditStatusEnum.WAIT.shadowColor = 'rgba(0,0,0,0.2)'
      auditStatusEnum.PASS.shadowColor = 'rgba(68,142,246,0.2)'
      auditStatusEnum.REJECT.shadowColor = 'rgba(250,50,25,0.2)'
      auditStatusEnum.WAIT.showText = '正在核验'
      auditStatusEnum.PASS.showText = '已核验通过'
      auditStatusEnum.REJECT.showText = '核验未通过'
      const CompanyAuditStatusEnum = this.getReferenceConstantMap('companyAuditStatusEnum')
      CompanyAuditStatusEnum.NOT.color = '#A8A8A8'
      CompanyAuditStatusEnum.WAIT.color = '#D9D9D9'
      CompanyAuditStatusEnum.SUCCESS.color = '#00B42A'
      CompanyAuditStatusEnum.FAIL.color = '#F53F3F'
      const invoiceAuditStatusEnum = this.getReferenceConstantMap('invoiceAuditStatusEnum')
      invoiceAuditStatusEnum.APPLYING.color = '#448EF6'
      invoiceAuditStatusEnum.INVOICE_AUDITING.color = '#448EF6'
      invoiceAuditStatusEnum.INVOICE_FAILED.color = '#F53F3F'
      invoiceAuditStatusEnum.INVOICE_CANCEL.color = '#F53F3F'
      invoiceAuditStatusEnum.INVOICE_MAKE_FAIL.color = '#F53F3F'
      invoiceAuditStatusEnum.INVOICE_RE_MAKE_FAIL.color = '#F53F3F'
      invoiceAuditStatusEnum.INVOICE_PASS.color = '#26BF26'

      const invoiceStatusEnum = this.getReferenceConstantMap('invoiceStatusEnum')
      invoiceStatusEnum.INVOICE_MAKE_FAIL.color = '#F53F3F'
      invoiceStatusEnum.INVOICE_MAKE_PASS.color = '#26BF26'
      invoiceStatusEnum.INVOICE_MAKING.color = '#448EF6'

      const paidStatusEnum = this.getReferenceConstantMap('paidStatusEnum')
      paidStatusEnum.FAILED.color = '#F53F3F'
      paidStatusEnum.PAID.color = '#00B42A'
      paidStatusEnum.REFUND.color = '#F53F3F'
      paidStatusEnum.UNPAID.color = '#FDB44B'
      paidStatusEnum.REFUNDING.color = '#FDB44B'
      paidStatusEnum.REFUND_FAIL.color = '#F53F3F'
      const isOutEnum = this.getReferenceConstantMap('isOutEnum')
      isOutEnum.EXPEND.color = '#FDB44B'
      isOutEnum.INCOME.color = '#00B42A'
      const answerStatusEnum = this.getReferenceConstantMap('answerStatusEnum')
      answerStatusEnum.UNDERWAY.color = '#FDB44B'
      answerStatusEnum.FINISHED.color = '#00B42A'
      const backSystemStatusEnum = this.getReferenceConstantMap('backSystemStatusEnum')
      backSystemStatusEnum.AUTHING.percentage = 50
      backSystemStatusEnum.CANCEL.percentage = 100
      backSystemStatusEnum.DOING.percentage = 80
      backSystemStatusEnum.DONE.percentage = 100
      backSystemStatusEnum.AUTHING.color = '#FDB44B'
      backSystemStatusEnum.CANCEL.color = 'red'
      backSystemStatusEnum.DOING.color = 'yellow'
      backSystemStatusEnum.DONE.color = 'green'

      /*  */
      backSystemStatusEnum.AUTHING.orderDetailColor = '#448EF6'
      backSystemStatusEnum.AUTHING.bgColor = '#FDB44B'
      backSystemStatusEnum.DOING.orderDetailColor = '#448EF6'
      backSystemStatusEnum.DOING.bgColor = '#FDB44B'
      backSystemStatusEnum.INVESTIGATING.orderDetailColor = '#448EF6'
      backSystemStatusEnum.INVESTIGATING.bgColor = '#FDB44B'
      backSystemStatusEnum.PLACING.orderDetailColor = '#448EF6'
      backSystemStatusEnum.PLACING.bgColor = '#FDB44B'


      backSystemStatusEnum.CANCEL.orderDetailColor = '#F53F3F'
      backSystemStatusEnum.CANCEL.bgColor = '#F53F3F'
      backSystemStatusEnum.PLACE_FAIL.orderDetailColor = '#F53F3F'
      backSystemStatusEnum.PLACE_FAIL.bgColor = '#F53F3F'
      backSystemStatusEnum.REFUNDING.orderDetailColor = '#F53F3F'
      backSystemStatusEnum.REFUNDING.bgColor = '#F53F3F'


      backSystemStatusEnum.DONE.orderDetailColor = '#00B42A'
      backSystemStatusEnum.DONE.bgColor = '#00B42A'
      backSystemStatusEnum.NOT_STARTED.orderDetailColor = '#D9D9D9'
      backSystemStatusEnum.NOT_STARTED.bgColor = '#D9D9D9'

      /*  */
      const backSystemLevelEnum = this.getReferenceConstantMap('backSystemLevelEnum')
      backSystemLevelEnum.BLUE.color = 'blue'
      backSystemLevelEnum.GREEN.color = 'green'
      backSystemLevelEnum.RED.color = 'red'
      backSystemLevelEnum.UN_KNOW.color = 'black'
      backSystemLevelEnum.YELLOW.color = 'yellow'
      const contractSignStatusEnum = this.getReferenceConstantMap('contractSignStatusEnum')
      contractSignStatusEnum.CANCEL.color = '#A8A8A8'
      contractSignStatusEnum.DRAFT.color = '#A8A8A8'
      contractSignStatusEnum.SIGNED.color = '#26BF26'
      contractSignStatusEnum.SIGNING.color = '#FDB44B'
      const statusEnum = this.getReferenceConstantMap('statusEnum')
      statusEnum.DONE.shareText = '再次参与'
      statusEnum.DOING.shareText = '继续邀请'
      statusEnum.FAIL.shareText = '助力失败'
      statusEnum.WAIT.shareText = '立即邀请好友'
      const shareActivityJoinStatusEnum = this.getReferenceConstantMap('shareActivityJoinStatusEnum')
      shareActivityJoinStatusEnum.FAIL.color = '#F53F3F'
      shareActivityJoinStatusEnum.FAIL.showText = '再次发起'
      shareActivityJoinStatusEnum.SUCCESS.color = '#00B42A'
      shareActivityJoinStatusEnum.SUCCESS.showText = '使用免费背调'
      shareActivityJoinStatusEnum.PROGRESS.color = '#A8A8A8'
      shareActivityJoinStatusEnum.PROGRESS.showText = '邀请好友助力'

      colorOptions.forEach(({
        referenceKey,
        constantKey,
        colorValue
      }) => setColor(referenceKey, constantKey, colorValue))
    },

    /* 设置系统信息 */
    async setSystemInfo() {
      this.systemInfo = await Taro.getSystemInfo()
    },

    /* 设置小程序信息 */
    setMiniProgramInfo() {
      const { miniProgram } = Taro.getAccountInfoSync()
      this.miniProgramInfo = miniProgram
    }
  },
})

/* 解决包未准备前使用 */
export function useGlobalWithoutStore() {
  return useGlobalStore(store)
}
