import homeBannerImg from '@/assets/images/home-banner.png'
import homeBannerImg1 from '@/assets/images/home-banner-1.png'
import selfCheckImg from '@/assets/images/self-check.png'
import companyCheckImg from '@/assets/images/company-check.png'
import callQrCodeImg from '@/assets/images/call-qr-code.png'
import assistBgImg from '@/assets/images/assist-bg.png'
import assistBgImg1 from '@/assets/images/assist-bg1.png'
import assistOrderTitleBgImg from '@/assets/images/assist-order-title-bg.png'
import assistFailImg from '@/assets/images/assist-fail.png'
import assistSuccessImg from '@/assets/images/assist-success.png'
import assistRuleBgImg from '@/assets/images/assist-rule-bg.png'
import noFoundEmptyImg from '@/assets/images/no-fount-empty.png'
import shareGuideImg from '@/assets/images/share-guide.png'
import shareTopBgImg from '@/assets/images/share-top-bg.png'
import coinImg from '@/assets/images/coin.png'
import wenhaoImg from '@/assets/images/wenhao.png'
import blueCoinImg from '@/assets/images/blue-coin.png'
import blackRightArrow from '@/assets/images/black-right-arrow.png'
import statBgImg1 from '@/assets/images/stat-bg1.png'
import personImg from '@/assets/images/person.png'
import personAddImg from '@/assets/images/person-add.png'
import personRightArrowImg from '@/assets/images/person-right-arrow.png'
import blackCoinImg from '@/assets/images/black-coin.png'
import lineBarImg from '@/assets/images/line-bar.png'
import shareRuleBgImg from '@/assets/images/share-rule-bg.png'
import kingCoinImg from '@/assets/images/king-coin.png'
import buildingsImg from '@/assets/images/buildings.png'
import grayRightArrowImg from '@/assets/images/gray-right-arrow.png'
import orangeRightArrowImg from '@/assets/images/orange-right-arrow.png'
import orangeEmptyImg from '@/assets/images/orange_empty.png'
import kefuImg from '@/assets/images/kefu.png'
import blueRightArrowImg from '@/assets/images/blue-right-arrow.png'
import ToExaminationImg from '@/assets/images/examination/To_examination.png'
import inviteTotalImg from '@/assets/images/invite-total.png'
import inviteImg from '@/assets/images/invite.png'
import gzhImg from '@/assets/images/gzh.png'
import iqIconImg from '@/assets/images/iq-icon.png'
import metalIconImg from '@/assets/images/metal-icon.png'
import eqIconImg from '@/assets/images/eq-icon.png'
import whiteRightSanjiaoImg from '@/assets/images/white-right-sanjiao.png'

export const useImage = {
  whiteRightSanjiaoImg,
  metalIconImg,
  iqIconImg,
  eqIconImg,
  homeBannerImg,
  homeBannerImg1,
  selfCheckImg,
  companyCheckImg,
  callQrCodeImg,
  assistBgImg,
  assistBgImg1,
  assistOrderTitleBgImg,
  assistFailImg,
  assistSuccessImg,
  noFoundEmptyImg,
  assistRuleBgImg,
  shareGuideImg,
  shareTopBgImg,
  coinImg,
  wenhaoImg,
  blueCoinImg,
  blackRightArrow,
  statBgImg1,
  personImg,
  personAddImg,
  personRightArrowImg,
  blackCoinImg,
  lineBarImg,
  shareRuleBgImg,
  kingCoinImg,
  buildingsImg,
  grayRightArrowImg,
  orangeRightArrowImg,
  orangeEmptyImg,
  kefuImg,
  blueRightArrowImg,
  ToExaminationImg,
  inviteTotalImg,
  inviteImg,
  gzhImg
}