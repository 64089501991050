import { App } from 'vue'
import BasicForm from '@/components/form/basic-form.vue'
import EmptyData from '@/components/empty-data/empty-data.vue'
import LoadingData from '@/components/loading-data/loading-data.vue'
import BasicIcon from '@/components/icon/basic-icon.vue'
import VerifyDialog from '@/components/dialog/verify-dialog.vue'
import InfoDialog from '@/components/dialog/info-dialog.vue'
import UpdateDialog from '@/components/dialog/update-dialog.vue'
import { setupNutComponent } from './setup-nut-components'


export function setupComponent(app: App<Element>) {
  setupNutComponent(app)

  app.component('UpdateDialog', UpdateDialog)
  app.component('InfoDialog', InfoDialog)
  app.component('VerifyDialog', VerifyDialog)
  app.component('BasicForm', BasicForm)
  app.component('EmptyData', EmptyData)
  app.component('LoadingData', LoadingData)
  app.component('BasicIcon', BasicIcon)

}