
// 判断是否是微信浏览器
export function isWeiXin() {
  let ua:string = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}
export function scrollLoadMore() {
  // 监听页面滚动
  document.addEventListener('scroll', () => {
    // 距离顶部或底部的阈值
    const threshold = 20
    // 距顶部
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    // 可视区高度
    let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
    // 滚动条总高度
    let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
    console.log(scrollTop + clientHeight + threshold >= scrollHeight)
    // 滚动触底
    if (scrollTop + clientHeight + threshold >= scrollHeight) {
      console.log('滚动触底')
    }
  })
}