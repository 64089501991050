import { PropType } from 'vue'

export const basicFormProps = {

  /* 表单属性 */
  modelValue: { // 为表单设置默认值
    type: Object,
    default: () => ({}),
  },
  class: {
    type: String,
    default: '',
  },
  onSubmit: {
    type: Function as PropType<(model: Recordable) => void>,
    default: () => {},
  },


  /* 表单配置项 */
  schemas: {
    type: Array as PropType<IForm.Schema[]>,
    required: true,
  },

  /* 提交按钮文本 */
  submitText: {
    type: String as PropType<string>,
    default: '提交',
  },

  /* 是否展示提交按钮 */
  ifShowSubmit: {
    type: Boolean as PropType<boolean>,
    default: true,
  },

  /* 表单类 */
  formClass: {
    type: String,
    default: ''
  },

  /* 表单布局 */
  layout: {
    type: String as PropType<'vertical' | 'horizontal'>,
    default: 'horizontal'
  },


  /* 表单项类名 */
  formItemClass: {
    type: String,
    default: ''
  },

  /* 是否禁用 */
  disabled: {
    type: Boolean,
    default: false,
  },

  /* 提交按钮是否固定底部 */
  submitIsFixed: {
    type: Boolean,
    default: true,
  },

  /* 表单项描述是否含有冒号 */
  hasColon: {
    type: Boolean,
    default: false,
  }
}
