<!-- 图标组件 -->

<template>
  <nut-icon v-bind="attrsIcon" />
</template>

<script setup>
import { useAttrs, computed } from 'vue'
import { basicIconProps } from './props'

/* types */

/* data */
const attrs = useAttrs()
const props = defineProps(basicIconProps)

/* 图标组件属性 */
const attrsIcon = computed(() => (props.isIconFont
  ? {
    'font-class-name': 'iconfont',
    'class-prefix': 'icon',
    ...attrs,
    ...props,
  }
  : {
    ...attrs,
    ...props,
  }))

/* logics */

/* methods */
</script>
