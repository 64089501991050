
import { ComputedRef, computed, unref } from 'vue'
import { FormItemRule } from '@nutui/nutui-taro/dist/types/__VUE/formitem/types'
import { isNull } from '@/common/is'

// 创建默认消息提示
export function createDefaultMessage(schema: IForm.Schema, validateMassage = true) {
  const { component = 'Input', label } = schema

  let message = ''
  switch (true) {
    case component.includes('Picker'):
    case component.includes('Select'):
    case component.includes('Cascader'):
    case component.includes('Checkbox'):
    case component.includes('Radio'):
    case component.includes('Switch'):
      message = '请选择'
      break
    default:
      message = '请输入'
      break
  }
  return validateMassage ? `${message}${label}` : message
}
// 创建表单配置规则
export function createSchemaRule(schema: IForm.Schema): FormItemRule[] {
  let rules: FormItemRule[] = schema.rules || []
  if (schema.required && rules?.findIndex(rule => Reflect.get(rule, 'required')) < 0) {
    const message = createDefaultMessage(schema, true)
    rules.push({
      message,
      validator: (value => {
        let isValid: boolean
        if (value === undefined || isNull(value)) {
          isValid = false
        } else if (Array.isArray(value) && value.length === 0) {
          isValid = false
        } else if (typeof value === 'string' && value.trim() === '') {
          isValid = false
        } else if (
          typeof value === 'object'
              && Reflect.has(value, 'checked')
              && Reflect.has(value, 'halfChecked')
              && Array.isArray(value.checked)
              && Array.isArray(value.halfChecked)
              && value.checked.length === 0
              && value.halfChecked.length === 0
        ) {
          isValid = false
        } else {
          isValid = true
        }

        return isValid
      })
    })
  }

  return rules
}


export function useFormRules(propsRef: ComputedRef<IForm.Props>) {
  const getRules = computed<Recordable<FormItemRule>>(() => {
    const rules: any = {}
    const { schemas } = unref(propsRef)
    // const { schemas, rules } = unref(propsRef) 不生效
    let schemasRules = {}
    schemas
      ?.filter(schema => schema.required || schema.rules)
      .forEach(schema => {
        let schemaRules: FormItemRule[] = schema.rules ?? []
        if (schema.required && schemaRules?.findIndex(rule => Reflect.get(rule, 'required')) < 0) {
          schemaRules.push({
            required: schema.required,
            message: createDefaultMessage(schema, true)
          })
        }
        schemasRules[schema.prop!] = schemaRules
      })
    return {
      ...rules,
      ...schemasRules,
    }
  })

  return {
    getRules,
  }
}
