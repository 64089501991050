<template>
  <div
    :class="props.textAlign === 'left' ? 'text-left' : 'text-right'"
  >
    <nut-switch
      v-bind="bindValue"
    />
  </div>
</template>

<script lang='ts' setup>
import { computed } from '@vue/reactivity'
import { useAttrs } from 'vue'
import { switchProps } from './props'
const props = defineProps(switchProps)
const attrs = useAttrs()

const bindValue = computed(() => ({
  ...attrs,
  ...props,

}))

</script>
