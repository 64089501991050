import { PropType, ExtractPropTypes } from 'vue'
// import {Picker} from '@tarojs/components'

export const referencePickerProps = {

  /* 表单项值 */
  modelValue: {
    type: [Number, String, Array] as PropType<any>,
    default: ''
  },

  /* 占位符 */
  placeholder: {
    type: String,
    default: ''
  },

  /* 全局枚举项键名 */
  referenceKey: {
    type: String as PropType<Reference.ReferenceKey>,
    default: '',
    required: true,
  },

  /* 是否禁用 */
  disabled: {
    type: Boolean,
    default: false,
  },

  /* 文字对齐 */
  textAlign: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
  },
  commonApiSelectType: {
    type: String,
    default: ''
  },
}

export type ReferencePickerProps = Partial<ExtractPropTypes<typeof referencePickerProps>>