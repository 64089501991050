import Taro from '@tarojs/taro'
import { isWeiXin } from './isWeixin'
import { useStorage } from '@/common/storage'
export function throttle(func, wait) {
  let last = 0
  return (...params) => {
    const current_time = +new Date()
    if (current_time - last > wait) {
      func.apply(this, params)
      last = +new Date()
    }
  }
}

export const throttleToLogin = throttle((redirectUrl = '/pages/auth/login') => {
  // 接口返回401，token失效后，应该把本地的token和user清理掉
  useStorage.removeItem('token')
  useStorage.removeItem('user')
  // 判断是否是微信浏览器
  if (isWeiXin()) {
    Taro.navigateTo({ url: redirectUrl })
  } else {
    Taro.navigateTo({ url: '/pages/auth/login-by-mobile' })
  }
}, 2000)