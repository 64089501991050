<!-- 单选按钮组 -->
<template>
  <div
    v-bind="getBindValue"
    class="flex"
  >
    <nut-icon
      class="text-theme-400"
      :name="modelValue ? iconActiveName : iconName"
    />
    <div>{{ label }}</div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { radioProps } from './props'

/* types */

/* data */
const props = defineProps(radioProps)
const emits = defineEmits(['update:modelValue'])
const getBindValue = computed(() => ({
  onClick: () => {
    emits('update:modelValue', props.isGroup ? props.value : props.modelValue)
  },
  ...props,
}))
</script>