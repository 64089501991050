// 业务无关自定义通用组件
import Input, { InputProps } from './components/common-components/input'
import CheckboxGroup, { CheckboxGroupProps } from './components/common-components/checkbox-group'
import DigitInput, { DigitInputProps } from './components/common-components/digit-input'
import IdcardInput, { IdcardInputProps } from './components/common-components/idcard-input'
import MobileInput, { MobileInputProps } from './components/common-components/mobile-input'
import NicknameInput, { NicknameInputProps } from './components/common-components/nickname-input'
import NumberInput, { NumberInputProps } from './components/common-components/number-input'
import CustomNumberInput, { CustomNumberInputProps } from './components/common-components/custom-number-input'
import BusinessLicenseInput, { BusinessLicenseInputProps } from './components/common-components/business-license-input'
import PasswordInput, { PasswordInputProps } from './components/common-components/password-input'
import RadioGroup, { RadioGroupProps } from './components/common-components/radio-group'
import InvoiceRadioGroup, { InvoiceRadioGroupProps } from './components/common-components/invoice-radio-group'
import ReferencePicker, { ReferencePickerProps } from './components/common-components/reference-picker'
import DatePicker, { DatePickerProps } from './components/common-components/date-picker'
import TimePicker, { TimePickerProps } from './components/common-components/time-picker'
import RegionPicker, { RegionPickerProps } from './components/common-components/region-picker'
import MultiSelectorPicker, { MultiSelectorPickerProps } from './components/common-components/multi-selector-picker'
import CascadePicker, { CascadePickerProps } from './components/common-components/cascade-picker'
import Picker, { PickerProps } from './components/common-components/picker'
import Switch, { SwitchProps } from './components/common-components/switch'

// 业务组件
import CommonPicker, { CommonPickerProps } from './components/business-components/common-picker'
import PaymentMethod, { PaymentMethodProps } from './components/business-components/payment-method'


export const componentTypeMap = {
  // 自定义通用组件
  Input,
  CheckboxGroup,
  DigitInput,
  IdcardInput,
  MobileInput,
  NicknameInput,
  NumberInput,
  CustomNumberInput,
  BusinessLicenseInput,
  PasswordInput,
  RadioGroup,
  ReferencePicker,
  DatePicker,
  TimePicker,
  RegionPicker,
  MultiSelectorPicker,
  CascadePicker,
  Picker,
  InvoiceRadioGroup,
  Switch,

  // 业务组件，调用特定业务接口
  CommonPicker,
  PaymentMethod,
}

export type ComponentType = keyof typeof componentTypeMap | 'Custom'


export type ComponentProp<T extends ComponentType> = {
  // 自定义通用组件
  'Input': InputProps,
  'CheckboxGroup': CheckboxGroupProps,
  'DigitInputGroup': DigitInputProps,
  'IdcardInput': IdcardInputProps,
  'MobileInput': MobileInputProps,
  'NicknameInput': NicknameInputProps,
  'NumberInput': NumberInputProps,
  'CustomNumberInput': CustomNumberInputProps,
  'BusinessLicenseInput': BusinessLicenseInputProps,
  'PasswordInputInput': PasswordInputProps,
  'RadioGroup': RadioGroupProps,
  'ReferencePicker': ReferencePickerProps,
  'DatePicker': DatePickerProps,
  'TimePicker': TimePickerProps,
  'RegionPicker': RegionPickerProps,
  'MultiSelectorPicker': MultiSelectorPickerProps,
  'CascadePicker': CascadePickerProps,
  'Picker': PickerProps,
  'InvoiceRadioGroup':InvoiceRadioGroupProps,
  'Switch':SwitchProps


  // 业务组件，调用特定业务接口
  'CommonPicker': CommonPickerProps,
  'PaymentMethodPicker': PaymentMethodProps,
  [K: string]: any,
}[T]