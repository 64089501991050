<!-- 单选按钮组 -->
<template>
  <div class="flex text-gray-1000">
    <CustomRadio
      v-for="(option, index) in getRadioOptions"
      v-bind="option"
      :class="{'ml-2': index}"
      :is-group="true"
      @update:modelValue="emits('update:modelValue', $event)"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { radioGroupProps } from './props'
import CustomRadio from '../radio'
import { cloneDeep } from 'lodash-es'

/* types */

/* data */
const props = defineProps(radioGroupProps)
const emits = defineEmits(['update:modelValue'])
const getRadioOptions = computed(() => props.options.map(option => {
  const newOption = cloneDeep(option)
  newOption.modelValue = newOption.value === props.modelValue

  return newOption
}))
</script>