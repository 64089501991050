import { IconProps } from '@tarojs/components/types/Icon'
import { ExtractPropTypes } from 'vue'
export const basicIconProps = {

  /* 是否使用iconfont */
  isIconFont: {
    type: Boolean,
    default: true,
  },
}
export type BasicIconProps = Partial<ExtractPropTypes<typeof basicIconProps>> & IconProps