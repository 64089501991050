import { PropType, ExtractPropTypes } from 'vue'

export const commonPickerProps = {

  /* 表单项值 */
  modelValue: {
    type: [Number, String] as PropType<number | string>,
    default: ''
  },

  /* 占位符 */
  placeholder: {
    type: String,
    default: ''
  },

  /* 是否禁用 */
  disabled: {
    type: Boolean,
    default: false,
  },

  /* 文字对齐 */
  textAlign: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
  },
  commonApiSelectType: {
    type: String,
    required: true,
  },
}
export type CommonPickerProps = Partial<ExtractPropTypes<typeof commonPickerProps>>