import { ExtractPropTypes, PropType } from 'vue'
import { PickerSelectorProps } from '@tarojs/components'

export const pickerProps = {

  /* 表单项值 */
  modelValue: {
    type: String,
    default: ''
  },

  options: {
    type: Array as PropType<Reference.ReferenceItem[]>,
    default: () => []
  },

  /* 是否禁用 */
  disabled: {
    type: Boolean,
    default: false,
  },

  /* 占位符 */
  placeholder: {
    type: String,
    default: ''
  },

  /* 文字对齐 */
  textAlign: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
  }
}

export type PickerProps = Partial<ExtractPropTypes<typeof pickerProps>> & PickerSelectorProps