import Taro from '@tarojs/taro'

/* data 数据 */
const duration = 1000 * 2 // 持续时长
let toastNum = 0 // toast 个数
let isLoading = false

export const useMessage = {

  /**
   * @description: 成功轻提示
   * @param {*} title 标题
   * @return {*} *
   */
  success(title = '操作成功') {
    return new Promise((resolve, reject) => {
      toastNum++
      isLoading = true
      Taro.showToast({
        title,
        duration,
        icon: title.length > 7 ? 'none' : 'success',
        complete: () => {
          setTimeout(() => {
            resolve(true)
            toastNum--
          }, duration)
        },
      }).catch(reject)
    })
  },

  /**
   * @description: 信息提示
   * @param {*} title 标题
   * @return {*} *
   */
  info(title = '温馨提示') {
    return new Promise((resolve, reject) => {
      toastNum++
      isLoading = true
      Taro.showToast({
        title,
        duration,
        icon: 'none',
        complete: () => {
          setTimeout(() => {
            resolve(true)
            toastNum--
          }, duration)
        },
      }).catch(reject)
    })
  },

  /**
   * @description: 失败轻提示
   * @param {*} title 标题
   * @return {*} *
   */
  error(title = '操作失败') {
    return new Promise((resolve, reject) => {
      toastNum++
      isLoading = true
      Taro.showToast({
        title,
        duration,
        icon: title.length > 7 ? 'none' : 'error',
        complete: () => {
          setTimeout(() => {
            resolve(true)
            toastNum--
          }, duration)
        },
      }).catch(reject)
    })
  },

  /**
   * @description: 加载中轻提示
   * @param {*} title 标题
   */
  loading(title = '加载中...') {
    if (!isLoading) {
      Taro.showLoading({
        title,
        mask: true
      })
      isLoading = true
    }
  },

  /**
   * @description: 隐藏轻提示
   */
  hideLoading() { // toast 和 loading 共用
    if (isLoading) {
      Taro.hideLoading()
      isLoading = false
    }
  },

  /**
   * @description: 获取触发后为关闭的轻提示总数
   * @return {*} *
   */
  getToastNum() {
    return toastNum
  },
}
