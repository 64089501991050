<!-- 选择器 -->

<template>
  <picker v-bind="getCommonPickerBindValue">
    <view :class="[textAlign==='left'?'text-left':'text-right', 'text-gray-1000']">
      <div
        v-if="modelValue"
        :class="{'text-gray-400': disabled}"
      >
        {{ getLabel }}
      </div>
      <div
        v-else
        class="text-gray-500"
      >
        {{ placeholder }}
      </div>
    </view>
  </picker>
</template>
<script lang="ts" setup>
import { computed, useAttrs, reactive } from 'vue'
import { globalAPI } from '@/api/global'
import { commonPickerProps } from './props'
// import { Picker } from '@tarojs/components'

/* data 数据 */
const attrs = useAttrs()
const emits = defineEmits(['update:modelValue'])
const props = defineProps(commonPickerProps)
const commonPickerDetail = reactive<any>({
  isShow: false,
  options: []
})
const getCommonPickerBindValue = computed(() => ({
  range: commonPickerDetail.options,
  rangeKey: 'label',
  headerText: props.placeholder,
  mode: 'selector',
  value: commonPickerDetail.options.findIndex(option => option.value === props.modelValue) === -1 ? 0 : commonPickerDetail.options.findIndex(option => option.value === props.modelValue),
  onChange: e => {
    emits('update:modelValue', commonPickerDetail.options[e.detail.value]?.value)
  },
  ...attrs,
}))
const getLabel = computed(() => commonPickerDetail.options.find(option => option.value == props.modelValue)?.label)

/* logics 逻辑 */
setCommonSelectOptions()

/* methods 方法 */
/* 设置选项 */
async function setCommonSelectOptions() {
  const options = await globalAPI.getCommonSelectOptions({ type: props.commonApiSelectType })
  commonPickerDetail.options = options.map(option => ({
    label: option.title,
    value: option.id,
  }))
}
</script>
