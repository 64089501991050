<!-- 基础表单 -->
<template>
  <nut-form
    ref="formElRef"
    :model-value="formModel"
    class="nut-form-item__label--font-bold nut-form-item__body--text-black"
  >
    <div
      v-for="schema in schemas"
    >
      <nut-form-item
        v-if="!schema.isHidden"
        :show-error-line="false"
        v-bind="omit(schema, ['componentProps', 'component', 'rules'])"
        :rules="createSchemaRule(schema)"
      >
        <component
          :is="componentTypeMap[schema.component || 'Input']"
          :placeholder="createDefaultMessage(schema)"
          :model-value="(formModel[schema.prop || ''] )"
          v-bind="schema.componentProps"
          @update:modelValue="setFormModel({ [schema.prop || '']: $event })"
        />
      </nut-form-item>
    </div>
    <div
      v-if="props.ifShowSubmit"
      class="g-page-bottom bg-white"
    >
      <div class="px-5 py-6">
        <nut-button
          class="rounded"
          type="primary"
          shape="square"
          block
          @click="submit"
        >
          提交
        </nut-button>
      </div>
    </div>
  </nut-form>
</template>

<script setup lang="ts">
import { useMessage } from '@/common/message'
import { computed, ref, Ref, toRaw } from 'vue'
import { useModel } from './hooks/model'
import { basicFormProps } from './props'
import { createFormContext } from './hooks/context'
import { createSchemaRule, createDefaultMessage } from './hooks/rule'
import { omit } from 'lodash-es'
import { componentTypeMap } from './component-type-map'

/* data 数据 */

const props = defineProps(basicFormProps)
const emits = defineEmits(['submit'])
const getPropsRef = computed<IForm.Props>(() => ({ ...props }))
const formElRef = ref<IForm.NutFormExposeMethod>()
const { formModel, setFormModel } = useModel(getPropsRef)

// const { getRules } = useFormRules(getPropsRef)

/* methods 方法 */
const checkFormMounted = (elRef): elRef is Ref<IForm.NutFormExposeMethod> => {
  if (!elRef.value) {
    useMessage.error('获取表单实例失败')
  }

  return Boolean(formElRef.value)
}

/* 提交 */
const submit = async () => {
  if (checkFormMounted(formElRef)) {
    await validate()
    emits('submit', toRaw(formModel))
  }
}
const validate = async (prop?: string) => {
  const rawFormModel = toRaw(formModel)
  if (checkFormMounted(formElRef)) {
    const validateDetail = await formElRef.value?.validate(prop)

    if (validateDetail.valid) {
      return Promise.resolve(rawFormModel)
    } else {
      useMessage.error(validateDetail.errors[0].message)
      return Promise.reject()
    }
  } else {
    return Promise.reject()
  }
}

/* logics 逻辑 */
const exportObj = {
  formModel,
  setFormModel,
  validate,
  submit,
}


defineExpose(exportObj)


createFormContext(exportObj)


</script>


