import { PropType, ExtractPropTypes } from 'vue'
import { InputProps as WxInputProps } from '@tarojs/components'

export const passwordInputProps = {

  /* 值 */
  modelValue: {
    type: String,
    default: '',
  },

  /* 文本对齐 */
  textAlign: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
  },

  /* 最大长度 */
  maxLength: {
    type: Number,
    default: 11,
  }
}

export type PasswordInputProps = Partial<ExtractPropTypes<typeof passwordInputProps>> & WxInputProps