<!-- 复选按钮组 -->
<!--
  tips 提示
  1. 组件自带disabled禁用属性
 -->
<template>
  <nut-checkboxgroup v-bind="getBindValue">
    <nut-checkbox
      v-for="(item, index) in options"
      :key="index"
      :label="item.value.toString()"
    >
      {{ item.label }}
    </nut-checkbox>
  </nut-checkboxgroup>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { checkboxGroupProps } from './props'

/* types */

/* data */
const props = defineProps(checkboxGroupProps)
const getBindValue = computed(() => ({
  ...props,
}))

/* logics */

/* methods */
</script>