<!-- input 输入框 -->
<template>
  <input
    v-bind="getBindValue"
  >
</template>

<script lang="ts" setup>
import { computed, useAttrs, nextTick } from 'vue'
import { mobileInputProps } from './props'

/* data 数据 */
const props = defineProps(mobileInputProps)
const attrs = useAttrs()
const emits = defineEmits(['update:modelValue'])
const getBindValue = computed(() => ({
  class: props.textAlign === 'left' ? 'text-left' : 'text-right',
  value: props.modelValue,
  type: 'number',
  onBlur: async e => {
    const value = e.detail.value.replace(/[^0-9]/gi, '').slice(0, props.maxLength)
    if (value === props.modelValue) {
      emits('update:modelValue', e.detail.value)
      await nextTick()
    }
    emits('update:modelValue', value)
  },
  ...attrs
}))

</script>