<!-- 更新弹框 -->
<!--
tips 提示
 -->
<template>
  <nut-dialog
    class="nut-dialog--single-button"
    :visible="isShowUpdateDialog"
    title="更新提示"
    content="新版本已经准备好，请确认重启应用！"
    no-cancel-btn
    @ok="applyUpdate"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import Taro from '@tarojs/taro'

/* config 配置 */
/* types 注释类型 */
/* data 数据 */
const updateManager = Taro.getUpdateManager()
const isShowUpdateDialog = ref(false)

/* logics 逻辑 */
updateManager.onUpdateReady(() => {
  isShowUpdateDialog.value = true
})

/* methods 方法 */
/* 更新版本 */
function applyUpdate() {
  updateManager.applyUpdate()
}
</script>