import { provide, inject } from 'vue'

const key = Symbol('BasicForm') // 注入的键名

/* 注入表单上下文 */
export function createFormContext(context: Partial<IForm.ExposeProps>) {
  provide(key, context)
}

/* 获取表单上下文 */
export function useFormContext(): IForm.ExposeProps {
  return inject(key) as IForm.ExposeProps
}
