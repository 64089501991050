<!-- 选择器 -->

<template>
  <picker
    v-bind="getPickerBindValue"
  >
    <view :class="[textAlign==='left'?'text-left':'text-right', 'text-gray-1000']">
      <div
        v-if="modelValue"
        :class="{'text-gray-400': disabled}"
      >
        {{ getLabel }}
      </div>
      <div
        v-else
        class="text-gray-500"
      >
        {{ placeholder }}
      </div>
    </view>
  </picker>
</template>
<script lang="ts" setup>
import { computed, reactive, useAttrs, watch } from 'vue'
// import { Picker } from '@nutui/nutui-taro';
import { Picker } from '@tarojs/components'
import { pickerProps } from './props'

/* types 类型注释 */
interface PickerDetail {
  options: Reference.ReferenceItem[]
}

/* data 数据 */
const attrs = useAttrs()
const emits = defineEmits(['update:modelValue'])
const props = defineProps(pickerProps)
const pickerDetail = reactive<PickerDetail>({
  options: [],
})
const getPickerBindValue = computed(() => ({
  range: pickerDetail.options,
  rangeKey: 'label',
  headerText: props.placeholder,
  mode: 'selector',
  value: pickerDetail.options.findIndex(option => option.value === props.modelValue) === -1 ? 0 : pickerDetail.options.findIndex(option => option.value === props.modelValue),
  onChange: e => {
    emits('update:modelValue', pickerDetail.options[e.detail.value]?.value)
  },
  ...attrs,
}))
const getLabel = computed(() => getPickerBindValue.value.range.find(option => option.value === props.modelValue)?.label)

/* logics 逻辑 */
watch(() => props.options, () => {
  pickerDetail.options = props.options
}, { immediate: true })


/* methods 方法 */
</script>
