<!-- 选择器 -->

<template>
  <picker
    v-if="false"
    v-bind="getPickerBindValue"
  >
    <view :class="[textAlign==='left'?'text-left':'text-right', 'text-gray-1000']">
      <div
        v-if="modelValue"
        :class="{'text-gray-400': disabled}"
      >
        <!-- {{ getLabel }} -->
      </div>
      <div
        v-else
        class="text-gray-500"
      >
        {{ placeholder }}
      </div>
    </view>
  </picker>
  <div>
    xxx{{ getPickerBindValue.xxx }}
  </div>
  <div>
    modelValue{{ modelValue }}
  </div>
  <div>
    pickerDetail.rangeOptions{{ pickerDetail.rangeOptions }}
  </div>

  <picker-view
    v-bind="getPickerBindValue"
    indicator-style="height: 50px; line-height: 50px"
    style="width: 100%; height: 300px;"
  >
    <picker-view-column
      v-for="(range, rangeIndex) in pickerDetail.rangeOptions"
      :key="rangeIndex"
    >
      <div
        v-for="(item, itemIndex) in range"
        :key="itemIndex"
      >
        {{ item.label }}
      </div>
    </picker-view-column>
    <picker-view-column v-if="false" />
  </picker-view>
</template>
<script lang="ts" setup>
import { computed, nextTick, reactive, useAttrs, watch } from 'vue'
// import { Picker } from '@nutui/nutui-taro';
import { Picker } from '@tarojs/components'
import { cascadePickerProps, CascadeReferenceItem } from './props'

/* types 类型注释 */
interface PickerDetail {
  rangeOptions: CascadeReferenceItem[][];
  indexValue: number[]
}

/* data 数据 */
const attrs = useAttrs()
const emits = defineEmits(['update:modelValue'])
const props = defineProps(cascadePickerProps)
const pickerDetail = reactive<PickerDetail>({
  rangeOptions: [],
  indexValue: [],
})
const getPickerBindValue = computed(() => ({
  // range: pickerDetail.rangeOptions,
  // rangeKey: 'label',
  headerText: props.placeholder,
  mode: 'multiSelector',
  // value: props.modelValue?.map((item, index) => pickerDetail.rangeOptions[index].findIndex((option => option.value === item))),
  value: pickerDetail.indexValue,
  onChange: e => {
    const newIndexValue = e.detail.value

    // console.log('update:modelValue modelValue', e.detail.value.map((index, itemIndex) => pickerDetail.rangeOptions[itemIndex][index].value))
    changeRangeOptions(e.detail.value)
    // emits('update:modelValue', e.detail.value.map((index, itemIndex) => pickerDetail.rangeOptions[itemIndex][index].value))
  },
  // xxx: props.modelValue?.map((item, index) => pickerDetail.rangeOptions[index].findIndex((option => option.value === item))),
  ...attrs,
}))
// const getLabel = computed(() => props.modelValue?.map((value, itemIndex) => pickerDetail.rangeOptions[itemIndex].find(option => option.value === value)?.label).join('，'))

/* methods  */

/* 设置级联选项 */
const setRangeOptions = async (options: CascadeReferenceItem[]) => {
  // console.log('options', options)
  pickerDetail.rangeOptions = [...pickerDetail.rangeOptions, options]
  const option = options[0]
  // emits('update:modelValue', [...props.modelValue, option.value])
  await nextTick()
  if (option.children) {
    setRangeOptions(option.children)
  }
  // console.log('pickerDetail.rangeOptions', toRaw(pickerDetail.rangeOptions))
}

/* 更改级联选项 */
const changeRangeOptions = (indexValue: number[]) => {
  const newRageOptions: CascadeReferenceItem[][] = []
  indexValue.reduce<Nullable<CascadeReferenceItem[]>>((options: Nullable<CascadeReferenceItem[]>, index, itemIndex) => {
    if (!options) {
      return null
    }
    console.log('options', options, indexValue)
    if (itemIndex) {
      newRageOptions.push(options[index].children!)
    } else {
      newRageOptions.push(options)
    }
    return options[index].children!
  }, props.options)
  // console.log('newRageOption', newRageOptions)
  pickerDetail.rangeOptions = newRageOptions
}

/* logics 逻辑 */
watch(() => props.options, () => {
  // emits('update:modelValue', [])
  pickerDetail.rangeOptions = []
  setRangeOptions(props.options)
  // console.log('pickerDetail.rangeOptions', pickerDetail.rangeOptions)
}, { immediate: true })
watch(() => props.modelValue, () => {
  const newIndexValue: number[] = []
  props.modelValue.reduce<CascadeReferenceItem[]>((options: CascadeReferenceItem[], value) => {
    const option = options.find((item, index) => {
      if (item.value === value) {
        newIndexValue.push(index)
      }
      return item.value === value
    })

    return option!.children
  }, props.options)
  if (newIndexValue.length) {
    changeRangeOptions(newIndexValue)
    pickerDetail.indexValue = newIndexValue
  }
  // console.log('pickerDetail.indexValue', pickerDetail.indexValue)
}, { immediate: true })

/* methods 方法 */

</script>
