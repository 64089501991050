import { debounce } from 'lodash-es'
import { useMessage } from './message'


/* 因版本更新迭代的速度很快，就会引起用户在应用当中，页面点击无反应，其实是打包的js和css的包名称更改，找不到以前的包的缘故。使用window.addEventListener('error')，判断js或css报错时，强制刷新页面，就可以正常使用了。
链接：https://www.jianshu.com/p/bcbacdf4a1f7 */

const setupUpdateCheck = () => {
  window.addEventListener('error', debounce(async eventErr => {
    if (eventErr.srcElement.localName === 'link' || eventErr.srcElement.localName === 'script') {
      await useMessage.info('因版本更新，页面需重新载入')
      let T = setTimeout(() => {
        // https://www.runoob.com/jsref/met-loc-reload.html
        // 如果把该方法的参数设置为 true，那么无论文档的最后修改日期是什么，它都会绕过缓存，从服务器上重新下载该文档。这与用户在单击浏览器的刷新按钮时按住 Shift 健的效果是完全一样。
        // MDN文档：Firefox 对于该方法支持一个非标准的 forceGet boolean 参数 ，当值为 true 时，将强制 Firefox 从服务器加载页面资源。但是在其他浏览器中任何参数都是无效的，location.reload() 执行时将会忽略它们并且不会有任何效果。
        // window.location.reload(true)

        // 获取当前origin属性，http://192.168.1.226:10086
        let currentHref = window.location.href
        if (window.location.search === '') {
          window.location.href = `${currentHref}?randomnumber=${Date.now()}`
        } else {
          window.location.href = `${currentHref}&randomnumber=${Date.now()}`
        }
        clearTimeout(T)
      }, 1000)
    }
    eventErr.preventDefault()
  }, 2000), true)
}
export default setupUpdateCheck