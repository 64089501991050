
import { useEnv } from '@/common/env'

export const projectConfig = {
  mockApiURL: 'https://yapi.gzhclw.com/mock/100', // 测试接口基础路径
  apiURL: `${useEnv.baseApiUrl}/api`, // 测试接口基础路径
  uploadURL: `${useEnv.baseApiUrl}/api/global/upload`, // 上传接口路径
  requestTimeout: 20000, // 单位ms
  isMock: false, // true false
}
