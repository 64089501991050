import { PropType, ExtractPropTypes } from 'vue'

export const paymentMethodProps = {

  originalMoney: {
    type: String,
    default: '0'
  },
  needMoney: {
    type: String,
    default: '0'
  },
  discountMoney: {
    type: String,
    default: '0'
  }


}
export type PaymentMethodProps = Partial<ExtractPropTypes<typeof paymentMethodProps>>