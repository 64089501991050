import { ExtractPropTypes, PropType } from 'vue'
import { PickerMultiSelectorProps } from '@tarojs/components'

export const cascadePickerProps = {

  /* 表单项值 */
  modelValue: {
    type: Array as PropType<(string | number)[]>,
    default: []
  },

  options: {
    type: Array as PropType<CascadeReferenceItem[]>,
    default: () => []
  },

  /* 是否禁用 */
  disabled: {
    type: Boolean,
    default: false,
  },

  /* 占位符 */
  placeholder: {
    type: String,
    default: ''
  },

  /* 文字对齐 */
  textAlign: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
  }
}

export interface CascadeReferenceItem {
  label: string;
  value: string | number;
  children: CascadeReferenceItem[]
}

export type CascadePickerProps = Partial<ExtractPropTypes<typeof cascadePickerProps>> & PickerMultiSelectorProps