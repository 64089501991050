import { PropType, ExtractPropTypes } from 'vue'
// import {CheckboxGroup} from '@nutui/nutui-taro'

export const checkboxGroupProps = {

  /* 复选框配置项 */
  options: {
    type: Array as PropType<Reference.ReferenceItem[]>,
    default: () => ([])
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  max: {
    type: Number,
    default: 999,
  },
}

export type CheckboxGroupProps = Partial<ExtractPropTypes<typeof checkboxGroupProps>>