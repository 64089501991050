<!-- 日期选择 -->
<template>
  <!-- 使用新属性代理 -->
  <picker v-bind="getBindValue">
    <view :class="[textAlign==='left'?'text-left':'text-right', 'text-gray-1000']">
      <div
        v-if="modelValue"
        :class="{'text-gray-400': disabled}"
      >
        {{ modelValue }}
      </div>
      <div
        v-else
        class="text-gray-500"
      >
        {{ placeholder }}
      </div>
    </view>
  </picker>
</template>

<script lang="ts" setup>
import { useAttrs, computed } from 'vue'
import { datePickerProps } from './props'

/* types */

/* data */
const props = defineProps(datePickerProps)

const emits = defineEmits(['update:modelValue'])
const attrs = useAttrs()
const getBindValue = computed(() => ({
  mode: 'date',
  onChange: e => {
    emits('update:modelValue', e.detail.value)
  },
  value: props.modelValue,
  disabled: props.disabled,
  ...attrs,
}))

/* logics */


</script>