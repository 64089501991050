
import { request } from '@/common/request'

const globalAPI = {

  /** 获取枚举类型 */

  getReference: () => request.get('/api/global/reference'),
  upload: params => request.post('/api/global/upload', params), // 上传
  getSignature: params => request.get('/api/signature', params),
}

export { globalAPI }