<!-- 认证弹框 -->
<!--
tips 提示
 -->
<template>
  <nut-dialog
    title="认证提示"
    content="您还未进行实名认证， 确认进行跳转"
    @ok="goVerify"
  />
</template>

<script lang="ts" setup>
import Taro from '@tarojs/taro'

/* config 配置 */
/* types 注释类型 */
/* data 数据 */

/* logics 逻辑 */
/* methods 方法 */
/* 加入认证页 */
function goVerify() {
  Taro.navigateTo({
    url: '/pages/my/user-data/user-data-auth'
  })
}
</script>