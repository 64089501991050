import { ExtractPropTypes } from 'vue'
// import {RadioGroup} from '@nutui/nutui-taro'

export const radioProps = {
  modelValue: {
    type: Boolean,
    default: false,
  },
  value: {
    type: [String, Number],
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false
  },
  shape: {
    type: String,
    default: 'round'
  },
  label: {
    type: String,
    default: false,
  },
  isGroup: {
    type: Boolean,
    default: false
  },
  iconName: {
    type: String,
    default: 'check-normal'
  },
  iconActiveName: {
    type: String,
    default: 'check-checked'
  },
  iconSize: {
    type: [String, Number],
    default: ''
  },
  iconClassPrefix: {
    type: String,
    default: 'nut-icon'
  },
  iconFontClassName: {
    type: String,
    default: 'nutui-iconfont'
  }
}

export type RadioProps = Partial<ExtractPropTypes<typeof radioProps>>