import { ComputedRef, unref, reactive, watch } from 'vue'

export function useModel(propsRef: ComputedRef<IForm.Props>) {
  const formModel = reactive<Recordable>({}) // 表单模型

  /* 监听模型下全部属性 */
  watch(
    () => ({ ...propsRef.value.modelValue }),
    () => {
      initFormModel()
    },
    {
      immediate: true,
    },
  )

  /* 参数化模型 */
  function initFormModel() {
    const { modelValue = {}, schemas } = unref(propsRef)
    schemas
      ?.filter(s => s.prop)
      .forEach(s => {
        let value: any = undefined
        if (modelValue[s.prop!]) {
          value = modelValue[s.prop!]
        }
        formModel[s.prop!] = value
      })
  }

  /* 设置模型 */
  function setFormModel(record: Recordable) {
    Object.keys(record).forEach(prop => {
      formModel[prop] = record[prop]
    })
  }

  return {
    formModel,
    setFormModel,
    initFormModel,
  }
}
