import { ExtractPropTypes, PropType } from 'vue'
import { PickerTimeProps } from '@tarojs/components'

export const timePickerProps = {

  /* 表单项值 */
  modelValue: {
    type: String,
    default: ''
  },

  /* 是否禁用 */
  disabled: {
    type: Boolean,
    default: false,
  },

  /* 占位符 */
  placeholder: {
    type: String,
    default: ''
  },

  /* 文字对齐 */
  textAlign: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
  }
}

export type TimePickerProps = Partial<ExtractPropTypes<typeof timePickerProps>> & PickerTimeProps