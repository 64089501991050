import { ExtractPropTypes, PropType } from 'vue'
import { RadioProps } from '@tarojs/components/types/Radio'

type RadioLabel = {
 label:string,
 value:string | number
}& RadioProps
export const invoiceRadioGroupProps = {
  radioLabel: {
    type: Array as PropType<RadioLabel[]>,
    default: []
  },

  /* 文本对齐 */
  textAlign: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
  },

}

export type InvoiceRadioGroupProps = Partial<ExtractPropTypes<typeof invoiceRadioGroupProps>>