
<style lang='less'>
</style>
<template>
  <div :class="props.textAlign === 'left' ? 'text-left' : 'text-right'">
    <nut-radiogroup
      v-bind="attrs"
    >
      <nut-radio
        v-for="(radio,index) in props.radioLabel"
        v-bind="radio"
        :label="radio.value || `${index}`"
      >
        {{ radio.label }}
      </nut-radio>
    </nut-radiogroup>
  </div>
</template>

<script lang='ts' setup>
import { useAttrs } from 'vue'
import { invoiceRadioGroupProps } from './props'
const props = defineProps(invoiceRadioGroupProps)

const attrs = useAttrs()

</script>
