<template>
  <div class="py-8 text-center">
    <img
      :style="{width: `${width}px`}"
      mode="widthFix"
      :src="getPath"
      alt=""
    >
    <div class="my-2 leading-5 text-sm">
      <div>
        {{ title }}
      </div>
      <div v-if="desc">
        {{ desc }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useImage } from '@/common/image'
import { computed } from 'vue'

const props = defineProps({

  /* 状态图片 */
  image: {
    type: String,
    default: '',
  },

  /* 标题 */
  title: {
    type: String,
    default: '暂无记录',
  },

  /* 描述 */
  desc: {
    type: String,
    default: ''
  },
  width: {
    type: Number,
    default: 96
  }
})
const getPath = computed(() => {
  let image = useImage.noFoundEmptyImg
  if (props.image) {
    return props.image
  }

  return image
})
</script>
