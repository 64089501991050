import { ExtractPropTypes, PropType } from 'vue'
import { SwitchProps as WxSwitchProps } from '@tarojs/components/types/Switch'


export const switchProps = {

  /* 文本对齐 */
  textAlign: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
  },


  /* nut自带 */
  modelValue: {
    type: [String, Number, Boolean],
    default: false
  },
  disable: {
    type: Boolean,
    default: false
  },
  activeColor: {
    type: String,
    default: ''
  },
  inactiveColor: {
    type: String,
    default: ''
  },
  activeText: {
    type: String,
    default: ''
  },
  inactiveText: {
    type: String,
    default: ''
  },
  activeValue: {
    type: [String, Number, Boolean],
    default: 1
  },
  inactiveValue: {
    type: [String, Number, Boolean],
    default: 0
  },
  loading: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    default: 'loading1'
  },
  size: {
    type: [String, Number],
    default: '12px'
  },
  color: {
    type: String,
    default: ''
  }

}
export type SwitchProps = Partial<ExtractPropTypes<typeof switchProps>>
