<!-- 选择器 -->

<template>
  <picker v-bind="getReferencePickerBindValue">
    <view :class="[textAlign==='left'?'text-left':'text-right', 'text-gray-1000']">
      <div
        v-if="modelValue"
        :class="{'text-gray-400': disabled}"
      >
        {{ getLabel }}
      </div>
      <div
        v-else
        class="text-gray-500"
      >
        {{ placeholder }}
      </div>
    </view>
  </picker>
</template>
<script lang="ts" setup>
import { computed, useAttrs } from 'vue'
// import { Picker } from '@nutui/nutui-taro';
import { Picker } from '@tarojs/components'
import { useGlobalStore } from '@/store/modules/global'
import { referencePickerProps } from './props'

/* types 类型注释 */

/* data 数据 */
const globalStore = useGlobalStore()
const attrs = useAttrs()
const emits = defineEmits(['update:modelValue'])
const props = defineProps(referencePickerProps)
const getReferencePickerBindValue = computed(() => ({
  range: globalStore.getReferenceItems(props.referenceKey),
  rangeKey: 'label',
  headerText: props.placeholder,
  mode: 'selector',
  value: globalStore.getReferenceItems(props.referenceKey).findIndex(option => option.value === props.modelValue) === -1 ? 0 : globalStore.getReferenceItems(props.referenceKey).findIndex(option => option.value === props.modelValue),
  onChange: e => {
    emits('update:modelValue', globalStore.getReferenceItems(props.referenceKey)[e.detail.value]?.value)
  },
  ...attrs,
}))
const getLabel = computed(() => globalStore.getReferenceItems(props.referenceKey).find(option => option.value === props.modelValue)?.label)
</script>
